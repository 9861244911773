// import { eventWrapper } from '@testing-library/user-event/dist/utils';
import React, {useState} from 'react'
export default function MyForm(props) {
    const ToUpperCase = ()=> {
        // console.log("CLicked");
        let newText = text.toUpperCase();
        setText(newText);
        props.showAlert("Converted to Uppercase","success");
    }
    const ToLowerCase = ()=>{
        let newText = text.toLowerCase();
        setText(newText);
        props.showAlert("Converted to Lowercase","success");
    }
    const copyText = ()=>{
        navigator.clipboard.writeText(text);
        props.showAlert("Text Copied","success");
        
    }
    const clearText = ()=>{
        let newText = "";
        setText(newText);
        props.showAlert("Text Cleared","success");
    }
    const ChangeHandle = (event)=> {
        // console.log("CLicked");
        setText(event.target.value);
    }
    const [text, setText] = useState("");
    // setText("Dont Enter Your text");
    return (
        <>
        <div className="container" style={{color: props.mode ==='light'?'black':'white'}} >
        <div>
        <h1>{props.heading}</h1>
 <div className="mb-3">
  <textarea className="form-control" id="myTextArea" value={text} onChange={ChangeHandle} style={{backgroundColor: props.mode ==='light'?'white':'rgb(31 30 30)', color: props.mode ==='light'?'black':'white'}}  rows="8"></textarea>
  <button disabled={text.length===0}className="btn btn-dark  my-3" onClick={ToUpperCase}>Uppercase</button>
  <button disabled={text.length===0} className="btn btn-dark my-3 mx-3" onClick={ToLowerCase}>Lowercase</button>
  <button disabled={text.length===0} className="btn btn-dark my-3 mx-3" onClick={copyText}>Copy text</button>
  <button disabled={text.length===0} className="btn btn-dark my-3 mx-2" onClick={clearText}>Clear</button>
 </div>
    </div>
    </div>
    <div className="container my-2" style={{color: props.mode ==='light'?'black':'white'}}>
        <h1>Text Summary</h1>
        <p>{text.split(/\s+/).filter((element)=>{ return element.length!==0}).length} Words and {text.length} Characters</p>
        <p>You Need {0.008 * text.split(" ").filter((element)=>{ return element.length!==0}).length} Minutes To Read</p>
        <h2>Preview</h2>
        <p>{text.length>0?text:"Nothing to preview"}</p>
    </div>
    </>
  )
}
