import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

export default function Navbar(props) {
  return (
    <nav className={`navbar navbar-expand-lg  bg-${props.mode} `} >
    <div className="container-fluid">
      <Link className="navbar-brand" to ="/"  style={{color: props.mode ==='light'?'black':'white'}}>{props.title}</Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" style={{color: props.mode ==='light'?'black':'white'}}></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link className="nav-link text-" aria-current="page" to ="/"  style={{color: props.mode ==='light'?'black':'white'}}>Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to ="/about"  style={{color: props.mode ==='light'?'black':'white'}}>About</Link>
          </li>
        </ul>
        <div className="d-flex">
          <div className="bg-primary rounded-circle mx-2" onClick={()=>{props.colorTheme('primary')}} style={{height:'25px',width:'25px',cursor:'pointer'}}></div>
          <div className="bg-warning rounded-circle mx-2" onClick={()=>{props.colorTheme('warning')}} style={{height:'25px',width:'25px',cursor:'pointer'}}></div>
          <div className="bg-success rounded-circle mx-2" onClick={()=>{props.colorTheme('success')}} style={{height:'25px',width:'25px',cursor:'pointer'}}></div>
          <div className="bg-danger rounded-circle mx-2" onClick={()=>{props.colorTheme('danger')}} style={{height:'25px',width:'25px',cursor:'pointer'}}></div>
        </div>
        <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox" onClick={()=>{props.colorTheme(null)}} role="switch" id="flexSwitchCheckDefault" />
        <label className={`form-check-label mx-2 text-${props.mode ==='light'?'dark':'light'}`} htmlFor="flexSwitchCheckDefault"><abbr title="Click to change theme">Theme</abbr></label>
        </div>
        <form className="d-flex" role="search">
          <input className="form-control me-2" type="search" list='search-items' placeholder="Search" aria-label="Search" />
          <datalist id='search-items'>
            <option value="Uppercase" ></option>
            <option value="Lowercase" ></option>
            <option value="clear" ></option>
            <option value="Preview" ></option>
          </datalist>
          <button className="btn btn-outline-success" type="submit">Search</button>
        </form>
        
      </div>
    </div>
  </nav>
  )
}
Navbar.propTypes = {
    title : PropTypes.string.isRequired,
    act : PropTypes.string
}
