import './App.css';
import Navbar from './components/Navbar.js'
import MyForm from './components/MyForm.js'
import Alert from './components/Alert.js'
import { useState } from 'react';
import About from './components/About.js';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
function App() {
   const[theme, setTheme] = useState("light");
   const[alert,setAlert] = useState(null);
   const showAlert = (message, type)=>{
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null)
    }, 1000);
   }
   const removeBodyClasses = ()=>{
     document.body.classList.remove('bg-primary');
     document.body.classList.remove('bg-warning');
     document.body.classList.remove('bg-success');
     document.body.classList.remove('bg-danger');
  }
  
   const toggleTheme = (cls)=> {
    removeBodyClasses();
    document.body.classList.add('bg-'+cls)
    if (theme === 'light') {
      setTheme('dark')
      document.body.style.backgroundColor = 'rgb(31 30 30)';
      showAlert("Dark Mode Activated", "dark");
    }
    else {
      setTheme('light')
      document.body.style.backgroundColor = '#f8f9fa';
      showAlert("Light Mode Activated", "success");
        }
   }
  return (
    <>
    <BrowserRouter>
<Navbar title = "TwoPodsText" act = "Hello" mode={theme} colorTheme = {toggleTheme}/> 
<Alert alert={alert}/>
<Routes>
  <Route exact path='/' element={<MyForm showAlert={showAlert} heading = "Enter Your Text For Manipulation" mode={theme}/>}></Route>
  <Route exact path='/about' element={<About mode={theme}/>}></Route>

</Routes>
<div className="container my-3">
 
</div>
</BrowserRouter>
    </>
  );
}

export default App;
